// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("packs/headroom.min")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Headroom from './headroom'
window.Headroom = Headroom

import Rails from "@rails/ujs";
window.Rails = Rails;

import $ from 'jquery';

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');

require("bootstrap");

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.upload-file').forEach(fileInput => {
    fileUpload(fileInput)
  })
})

$( document ).ready(function() {
  $( ".site-header .alert" ).click(function() {
    $(this).slideUp("fast");
  });
  
  $("[data-action='sortable']").sortable({
    update: function(e, ui) {
      Rails.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize'),
      });
  
    },
    
    items: "li:not(.ui-state-disabled), tr:not(.ui-state-disabled)"
  });
});